var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{style:({
    'padding-right': _vm.createCommand || _vm.$slots.extraButtons ? '15px' : 0
  })},[_c('div',{staticClass:"row"},[_c('form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();}}},[(_vm.$slots.statistics)?_c('div',{staticClass:"col-md-4"},[_vm._t("statistics"),_c('div',{staticClass:"hidden-md hidden-lg",staticStyle:{"margin-bottom":"10px"}})],2):_vm._e(),_c('div',{staticClass:"search-column",class:[
          _vm.createCommand ? 'col-xs-9 col-sm-10 col-md-6' : 'col-xs-12',
          !_vm.$slots.statistics && _vm.createCommand ? 'col-md-offset-2' : ''
        ]},[_c('div',{staticClass:"form-group",class:{
            'form-group-search': _vm.createCommand || _vm.$slots.extraButtons
          }},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"query",staticClass:"form-control",attrs:{"type":"text","data-testid":"search","placeholder":_vm.$t('placeholders.search')},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}}),_c('div',{staticClass:"input-group-addon btn",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onResetButtonClick.apply(null, arguments)}}},[(_vm.query)?_c('i',{staticClass:"fa fa-close"}):_c('i',{staticClass:"fa fa-search"})])])]),_vm._t("customFilters")],2),(_vm.createCommand)?[(
            _vm.createCommand.commands &&
            _vm.createCommand.commands.length &&
            _vm.isCommandEnabled(_vm.createCommand)
          )?_c('div',{staticClass:"form-group col-xs-3 col-sm-2 col-md-2 text-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"btn-group form-group btn-new-item"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-testid":"create-new","disabled":!_vm.isCommandEnabled(_vm.createCommand)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onCreateButtonClick(_vm.createCommand.name)}}},[_c('i',{staticClass:"fa fa-plus"}),_c('span',{staticClass:"hidden-xs"},[_vm._v(" "+_vm._s(_vm.$tc(_vm.createCommand.title))+" ")])]),_vm._m(0),_c('ul',{staticClass:"dropdown-menu"},_vm._l((_vm.createCommand.commands),function(item,ix){return _c('li',{key:ix},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onCreateButtonClick(item.name)}}},[_vm._v(" "+_vm._s(_vm.$tc(item.title))+" ")])])}),0),(_vm.$slots.extraButtons)?[_vm._t("extraButtons")]:_vm._e()],2)])])]):_c('div',{staticClass:"form-group col-xs-3 col-sm-2 col-md-2"},[_c('div',{staticClass:"btn-group form-group btn-new-item pull-right"},[_c('button',{staticClass:"btn",class:_vm.isCommandEnabled(_vm.createCommand)
                  ? 'btn-primary'
                  : 'btn-default',attrs:{"data-testid":"create-new","disabled":!_vm.isCommandEnabled(_vm.createCommand)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onCreateButtonClick(_vm.createCommand.name)}}},[_c('i',{staticClass:"fa fa-plus"}),_c('span',{staticClass:"hidden-xs"},[_vm._v(" "+_vm._s(_vm.$tc(_vm.createCommand.title))+" ")])]),(_vm.$slots.extraButtons)?[_vm._t("extraButtons")]:_vm._e()],2)])]:_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-primary dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{staticClass:"caret"}),_c('span',{staticClass:"sr-only"},[_vm._v("Toggle Dropdown")])])
}]

export { render, staticRenderFns }